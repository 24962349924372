import { useEffect, useState } from "react"

export function useScrollPosition() {
  const [scroll, setScroll] = useState(() =>
    typeof window === "undefined" ? 0 : window.scrollY,
  )

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const listener = () => setScroll(window.scrollY)

    window.addEventListener("scroll", listener)

    return () => window.removeEventListener("scroll", listener)
  }, [])

  return scroll
}
