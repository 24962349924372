import React, { FC } from "react"
import AngleDown from "src/components/Icons/AngleDown"
import { useId } from "src/hooks/useId"
import { Props } from "./types"
import * as styles from "./styles.module.scss"

const Dropdown: FC<Props> = ({
  contentClassName = "",
  children,
  mobileOnly = false,
  title,
  darkMode,
}) => {
  const id = useId()
  const mobileOnlyClass = mobileOnly ? styles.mobileOnly : ""

  return (
    <div className={darkMode ? styles.darkMode : ""}>
      <input className={styles.toggle} type="checkbox" name={id} id={id} />
      <label
        className={`${styles.label} ${mobileOnlyClass}`}
        htmlFor={id}
        data-x="red"
      >
        <h5>{title}</h5>
        <AngleDown aria-hidden="true" />
      </label>
      <div
        className={`${styles.content} ${mobileOnlyClass} ${contentClassName}`}
      >
        {children}
      </div>
    </div>
  )
}

export default Dropdown
