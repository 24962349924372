import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    {children}
    <path
      id="Globe"
      fill="currentColor"
      d="M24,48C9.422,48,0,38.578,0,24S9.422,0,24,0,48,9.422,48,24,38.578,48,24,48ZM40.118,33H34.41a34.247,34.247,0,0,1-1.8,7.331A14.754,14.754,0,0,0,40.118,33ZM24,42c1.434,0,3.352-2.911,4.344-9H19.656C20.648,39.089,22.566,42,24,42Zm-8.611-1.669A34.247,34.247,0,0,1,13.59,33H7.882A14.753,14.753,0,0,0,15.389,40.331ZM6.177,21a25.469,25.469,0,0,0,0,6h6.888C13.026,26.026,13,25.033,13,24s0.026-2.026.065-3H6.177Zm1.7-6H13.59a34.247,34.247,0,0,1,1.8-7.331A14.754,14.754,0,0,0,7.882,15ZM24,6c-1.434,0-3.352,2.911-4.344,9h8.687C27.352,8.911,25.434,6,24,6Zm4.929,15H19.071C19.029,21.958,19,22.946,19,24s0.029,2.042.071,3h9.859c0.041-.958.071-1.946,0.071-3S28.971,21.958,28.929,21ZM32.612,7.669A34.247,34.247,0,0,1,34.41,15h5.708A14.754,14.754,0,0,0,32.612,7.669ZM41.823,21H34.935C34.974,21.974,35,22.967,35,24s-0.026,2.026-.065,3h6.887A25.469,25.469,0,0,0,41.823,21Z"
    />
  </svg>
))
