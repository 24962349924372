import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    stroke="currentColor"
    viewBox="0 0 25 44"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Chevron_Right"
      data-name="Chevron Right"
      className="cls-1"
      d="M25.105,19.842L4.671,0.435a1.5,1.5,0,0,0-2.125,0L0.422,2.555a1.5,1.5,0,0,0,0,2.121L18.753,21.984,0.422,39.292a1.5,1.5,0,0,0,0,2.121l2.125,2.121a1.5,1.5,0,0,0,2.125,0L25.105,24.126A3.025,3.025,0,0,0,25.105,19.842Z"
    />
  </svg>
))
