import React, { ComponentType, FC } from "react"
import { useId } from "src/hooks/useId"
import { AcceptedProps, InjectedProps, LocalizedString } from "./types"

export default function accessibleSvg(
  Svg: ComponentType<InjectedProps>,
  defaultTitle?: LocalizedString,
  defaultDesc?: LocalizedString,
): FC<AcceptedProps> {
  return ({ children, title, desc, lang, ...props }) => {
    const titleId = useId()
    const descId = useId()

    const actualTitle = title || (lang && defaultTitle && defaultTitle[lang])
    const actualDesc = desc || (lang && defaultDesc && defaultDesc[lang])

    const ariaLabelledBy = [
      ...(actualTitle ? [titleId] : []),
      ...(actualDesc ? [descId] : []),
    ].join(" ")

    return (
      <Svg
        {...{
          ...(ariaLabelledBy
            ? {
                "aria-labelledby": ariaLabelledBy,
              }
            : {}),
          role: "img",
        }}
        {...props}
      >
        {children}
        {actualTitle && <title id={titleId}>{actualTitle}</title>}
        {actualDesc && <desc id={descId}>{actualDesc}</desc>}
      </Svg>
    )
  }
}
