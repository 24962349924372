import React, { FC } from "react"
import { Props } from "./types"
import Dropdown from "src/components/Drowdown"
import Link from "src/utils/Link"
import * as styles from "./styles.module.scss"
import { usePages } from "src/context/pages"
import { useStaticQuery, graphql } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import { PageTuple } from "src/pages"
import { createOptionFromLinkEntry } from "src/components/Header/navigation"

const MainFooter: FC<Props> = ({ lang, darkMode }) => {
  const { pages } = usePages()

  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/MainFooter\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            columns {
              name,
              links
            }
          }
          relativePath
        }
      }
    }
  `)

  const {
    columns,
  }: {
    columns: Array<{
      name: string
      links: PageTuple[]
    }>
  } = useComponentTexts(data)

  return (
    <div
      className={`content-wrapper ${styles.grid} ${
        darkMode ? styles.darkMode : ""
      }`}
    >
      {columns.map(column => {
        const options = column.links.map(page => {
          return createOptionFromLinkEntry(page, pages)
        })

        return (
          <div key={`column-${column.name}-title`} className={styles.column}>
            <Dropdown
              contentClassName={styles.links}
              mobileOnly={true}
              title={column.name}
              darkMode={darkMode}
            >
              {options.map(option => (
                <Link
                  className={styles.link}
                  lang={lang}
                  to={option.url}
                  target={option.target}
                  key={`column-${column.name}-option-${option.id}`}
                >
                  {option.name}
                </Link>
              ))}
            </Dropdown>
          </div>
        )
      })}
    </div>
  )
}

export default MainFooter
