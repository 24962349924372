import React, { FC, useContext } from "react"
import Legal from "./LegalFooter"
import Main from "./MainFooter"
import * as styles from "./styles.module.scss"
import { LangContext } from "src/context/lang"
import { Props } from "./types"
import SecondFooter from "src/components/Footer/SecondFooter"

const FooterComponent: FC<Props> = ({ darkMode }) => {
  const { lang } = useContext(LangContext)

  return (
    <footer className={darkMode ? styles.footerDark : styles.footer}>
      <Main lang={lang} darkMode={darkMode} />
      <SecondFooter lang={lang} darkMode={darkMode} />
      <Legal lang={lang} darkMode={darkMode} />
    </footer>
  )
}

export default FooterComponent
