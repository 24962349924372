import React, { FC, useRef } from "react"
import { Props } from "src/components/Header/types"
import { useLockBodyScroll } from "src/hooks/useLockBodyScroll"
import "./logic.scss"

const Menu: FC<Props> = ({
  children,
  dropdownClass,
  darkMode,
  isOpen,
  setIsOpen,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  useLockBodyScroll(contentRef, isOpen)

  return (
    <div className={`${isOpen ? "open" : ""} ${darkMode ? "darkmode" : ""}`}>
      <input
        type="checkbox"
        id="nav-dropdown-toggle"
        name="nav-dropdown-toggle"
        style={{ display: "none" }}
      />
      <label
        htmlFor="nav-dropdown-toggle"
        id="nav-dropdown-toggle-label"
        onClick={setIsOpen}
        style={{ display: "block" }}
      >
        <svg width="22" height="22">
          <path
            d="M 0 6 C 0 5.448 0.448 5 1 5 L 21 5 C 21.552 5 22 5.448 22 6 L 22 6 C 22 6.552 21.552 7 21 7 L 1 7 C 0.448 7 0 6.552 0 6 Z"
            fill="currentColor"
          />
          <path
            d="M 0 16 C 0 15.448 0.448 15 1 15 L 21 15 C 21.552 15 22 15.448 22 16 L 22 16 C 22 16.552 21.552 17 21 17 L 1 17 C 0.448 17 0 16.552 0 16 Z"
            fill="currentColor"
          />
        </svg>
      </label>
      <div id="nav-dropdown" className={`${dropdownClass}`} ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default Menu
