import { useEffect } from "react"
import Cookie from "js-cookie"

export function useFlowCookies() {
  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const query = window.location.search.substring(1)
    const search = new URLSearchParams(query)
    const referralCode = search.get("rsCode")

    const utmCookie = Cookie.get("flow_signup_attrs")

    if (!utmCookie) {
      const utmAttributes = {
        utm_campaign: search.get("utm_campaign"),
        utm_medium: search.get("utm_medium"),
        utm_code: query,
      }

      setCookie("flow_signup_attrs", JSON.stringify(utmAttributes))
    }

    if (referralCode) {
      setCookie("flow_referral_code", referralCode)
    }
  }, [])
}

function setCookie(name: string, content: string) {
  Cookie.set(name, content, {
    path: "/",
    expires: 30,
    secure: process.env.NODE_ENV === "production",
    domain: "." + process.env.GATSBY_BASE_DOMAIN,
  })
}
