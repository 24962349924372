import React, { FC } from "react"
import { Props } from "./types"
import Link from "src/utils/Link"
import * as styles from "./styles.module.scss"
import LanguageSelector from "../LanguageSelector"
import Twitter from "src/components/Icons/Twitter"
import Youtube from "src/components/Icons/Youtube"
import { usePages } from "src/context/pages"
import { useStaticQuery, graphql } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import { PageTuple } from "src/pages"

const LegalFooter: FC<Props> = ({ lang, darkMode }) => {
  const { getArrayPagesWithName, getPage } = usePages()

  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/LegalFooter\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            copyright
            links
          }
          relativePath
        }
      }
    }
  `)

  const {
    links,
    copyright,
  }: {
    links: PageTuple[]
    copyright: string
  } = useComponentTexts(data)

  const legalLinks = getArrayPagesWithName(Object.fromEntries(links))

  return (
    <div className={darkMode ? styles.legalFooterDark : styles.legalFooter}>
      <div className={`content-wrapper ${styles.grid}`}>
        <div className={styles.links}>
          {legalLinks.map(option => (
            <Link
              className={styles.link}
              lang={lang}
              to={option.url}
              key={`option-${option.id}`}
            >
              {option.name}
            </Link>
          ))}
        </div>
        <div className={styles.actions}>
          <Link
            className={styles.rrss}
            lang={lang}
            to={getPage("twitter").url}
            target="_blank"
          >
            <Twitter />
          </Link>
          <Link
            className={styles.rrss}
            lang={lang}
            to={getPage("youtube").url}
            target="_blank"
          >
            <Youtube />
          </Link>
          <LanguageSelector
            lang={lang}
            className={styles.languageSelector}
            darkMode={darkMode}
          />
        </div>
      </div>
      <div className={`content-wrapper ${styles.grid}`}>
        <span className={styles.copyright}>
          &copy; {new Date().getFullYear()} {copyright}
        </span>
        <span className={styles.copyright}>
          Various trademarks held by their respective owners.
        </span>
      </div>
    </div>
  )
}

export default LegalFooter
