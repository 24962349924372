import { graphql, useStaticQuery } from "gatsby"
import React, { FC, Fragment, useContext, useState } from "react"
import * as styles from "./styles.module.scss"
import { LangContext } from "src/context/lang"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import Logo from "src/components/Icons/Logo"
import MoreMenu from "./MoreMenu"
import { HeaderComponentProps, Props } from "./types"
import Link from "src/utils/Link"
import "./drawer-logic.scss"
import MobileMenu from "./MobileMenu"
import Chevron from "src/components/Icons/AngleRight"

import {
  createOptionFromLinkEntry,
  createOptionFromPageObject,
} from "./navigation"
import { useScrollPosition } from "src/hooks/useScrollPosition"
import { usePages } from "src/context/pages"
import { useScrollDirection } from "src/hooks/useScrollDirection"
import FocusWithin from "react-focus-within"
import { PinnedBottomBanner } from "./PinnedBottomBanner"

const AnchorPoint: FC = () => {
  const { getPage } = usePages()
  return (
    <div className={styles.cta}>
      <Link
        className={`button ${styles.darkModeButton}`}
        to={getPage("flow-x").url + "#GetAccess"}
      >
        Get Access
      </Link>
    </div>
  )
}

const CtaLink: FC<Props> = ({ texts }) => {
  const { getPage } = usePages()

  return (
    <div className={styles.cta}>
      <Link
        className={`button ${styles.loginLink} ${styles.signIn}`}
        to={getPage("app").url}
        target="_blank"
      >
        {texts.login}
      </Link>
    </div>
  )
}
const CtaLinkTry: FC<Props> = ({ texts }) => {
  const { getPage } = usePages()

  return (
    <div className={styles.cta}>
      <Link
        className={`button button-second ${styles.tryLink}`}
        to={getPage("signup").url}
      >
        {texts.tryItFree}
      </Link>
    </div>
  )
}

const TrialCtaLink: FC<Props> = ({ texts, variant }) => {
  const { getPage } = usePages()

  return (
    <div
      className={`${styles.cta} ${styles.ctaTrial} ${
        variant === "scroll-down" ? styles.ctaTrialOpen : ""
      }`}
    >
      <Link
        className={`button button-second ${styles.tryLink}`}
        to={getPage("signup").url}
      >
        {texts.trialCta}
      </Link>
    </div>
  )
}

const LogoLink: FC<Props> = ({ texts, isBlog }) => {
  const { getPage } = usePages()

  return (
    <li className={`${styles.logo} ${isBlog ? styles.logoBlog : ""}`}>
      <Link className={styles.linkLogo} to={getPage("index").url}>
        <Logo title={texts.home} desc={texts.goToTheHomePage} />
      </Link>
      {isBlog && (
        <>
          <div className={styles.logoDivider}></div>
          <Link to={getPage("blog").url} className={styles.blogLogo}>
            {texts.blog}
          </Link>
        </>
      )}
    </li>
  )
}

const TopBanner: FC<{ className?: string; caption: string }> = ({
  className,
  caption,
}) => {
  const { getPage } = usePages()

  return (
    <Link
      className={`${styles.strip} ${className}`}
      to={getPage("teams/project-management-for-remote-teams-fm").url}
    >
      <p
        className={`content-wrapper ${styles.stripText}`}
        dangerouslySetInnerHTML={{ __html: caption }}
      />
    </Link>
  )
}

const DesktopNav: FC<Props> = ({ texts, variant, isBlog, scrollDirection }) => {
  const [hover, setHover] = useState<string>()
  const { lang } = useContext(LangContext)
  const { pages, currentPathWithoutLang } = usePages()
  const isHomepage = currentPathWithoutLang === "/"
  const isFlowXpage = currentPathWithoutLang === "/flow-x"

  const whyFlow = createOptionFromLinkEntry(texts.links[0], pages)
  const pricing = createOptionFromLinkEntry(texts.links[1], pages)

  let showNavClass = ""

  switch (scrollDirection) {
    case "up":
      if (variant === "scroll-up") {
        showNavClass = styles.show
      } else {
        showNavClass = styles.hide
      }
      break
    case "down":
      if (variant === "scroll-down") {
        showNavClass = styles.show
      } else {
        showNavClass = styles.hide
      }
      break
  }

  if (isBlog) {
    return (
      <ul className={`${styles.navWrapper} ${styles.desktop} content-wrapper `}>
        <LogoLink texts={texts} isBlog={isBlog} />
        <CtaLinkTry texts={texts} />
      </ul>
    )
  }

  return (
    <ul
      className={`${styles.navWrapper} content-wrapper ${styles.desktop} ${showNavClass} `}
    >
      <LogoLink texts={texts} />
      <div className={styles.navContainer}>
        <div
          className={`${styles.navContent} ${
            variant === "scroll-up" ? styles.show : styles.hide
          }`}
        >
          <li className={styles.link}>
            <Link
              to={whyFlow.url}
              className={styles.link}
              activeClassName={styles.active}
            >
              {whyFlow.name}
            </Link>
          </li>
          {texts.dropdowns.map(dropdown => {
            const options = dropdown.items.map(entry =>
              createOptionFromPageObject(entry, pages),
            )

            function onHoverIn() {
              setHover(dropdown.name)
            }

            function onHoverOut() {
              setHover(current =>
                current === dropdown.name ? undefined : current,
              )
            }

            return (
              <FocusWithin
                key={dropdown.name}
                onFocus={onHoverIn}
                onBlur={onHoverOut}
              >
                {({ getFocusProps }) => (
                  <li
                    className={`${styles.link} ${styles.hasChild} ${
                      hover === dropdown.name ? styles.hover : ""
                    }`}
                    onMouseEnter={onHoverIn}
                    onMouseLeave={onHoverOut}
                    {...getFocusProps()}
                  >
                    <button className={styles.dropdownButton}>
                      {dropdown.name}
                      <MoreMenu
                        lang={lang}
                        links={options}
                        leftOption={createOptionFromLinkEntry(
                          dropdown.leftLink,
                          pages,
                        )}
                        rightOption={createOptionFromLinkEntry(
                          dropdown.request,
                          pages,
                        )}
                      />
                    </button>
                  </li>
                )}
              </FocusWithin>
            )
          })}
          <li className={styles.link}>
            <Link
              to={pricing.url}
              className={styles.link}
              activeClassName={styles.active}
            >
              {pricing.name}
            </Link>
          </li>
        </div>
        <div
          className={`${styles.navOffer} ${
            variant === "scroll-down" ? styles.show : styles.hide
          }`}
        >
          <li className={styles.offerDescription}>
            {texts.trialDescriptionPoints.map((text: string, index: number) => (
              <span key={index}>{text}</span>
            ))}
          </li>
        </div>
      </div>
      {isHomepage && <CtaLink texts={texts} />}
      {isFlowXpage && <AnchorPoint />}
      {!isFlowXpage && !isHomepage && <CtaLinkTry texts={texts} />}

      <TrialCtaLink texts={texts} variant={variant} />
    </ul>
  )
}

const MobileNav: FC<Props> = ({ texts, darkMode }) => {
  const { pages } = usePages()
  const whyFlow = createOptionFromLinkEntry(texts.links[0], pages)
  const pricing = createOptionFromLinkEntry(texts.links[1], pages)
  const tryDays = createOptionFromLinkEntry(texts.try, pages)
  const sales = createOptionFromLinkEntry(texts.sales, pages)
  const request = createOptionFromLinkEntry(texts.request, pages)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <ul
      className={`${styles.navWrapper} content-wrapper ${styles.mobile} ${
        isDrawerOpen ? styles.isMenuOpen : ""
      }`}
    >
      <LogoLink texts={texts} />
      <MobileMenu
        texts={texts}
        dropdownClass={styles.dropdown}
        darkMode={darkMode}
        isOpen={isDrawerOpen}
        setIsOpen={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <div
          className={`${styles.sectionMobile} ${styles.sectionMobileExternal}`}
        >
          <Link
            to={whyFlow.url}
            className={styles.sectionItemMobile}
            activeClassName={styles.active}
          >
            <span>{whyFlow.name}</span>
            <Chevron />
          </Link>
        </div>

        {texts.dropdowns.map(dropdown => {
          const options = dropdown.items.map(entry =>
            createOptionFromPageObject(entry, pages),
          )

          return (
            <Fragment key={dropdown.name}>
              <div className={styles.sectionMobile}>
                <span className={styles.sectionTitle}>{dropdown.name}</span>
                {options.map(option => (
                  <Link
                    to={option.url}
                    key={option.name}
                    className={styles.sectionItemMobile}
                  >
                    <span>{option.name}</span>
                    <Chevron />
                  </Link>
                ))}
              </div>
            </Fragment>
          )
        })}
        <div
          className={`${styles.sectionMobile} ${styles.sectionMobileExternal}`}
        >
          <Link
            to={pricing.url}
            className={styles.sectionItemMobile}
            activeClassName={styles.active}
          >
            <span>{pricing.name}</span>
            <Chevron />
          </Link>
        </div>
        <div className={styles.sectionMobile}>
          <span className={styles.sectionTitle}>{texts.getStarted}</span>

          <Link
            to={tryDays.url}
            className={`${styles.sectionItemMobile} ${styles.sectionItemMobileBlue}`}
          >
            <span>{tryDays.name}</span>
            <Chevron />
          </Link>
          <Link
            to={sales.url}
            className={`${styles.sectionItemMobile} ${styles.sectionItemMobileBlue}`}
          >
            <span>{sales.name}</span>
            <Chevron />
          </Link>
          <Link
            to={request.url}
            className={`${styles.sectionItemMobile} ${styles.sectionItemMobileBlue}`}
          >
            <span>{request.name}</span>
            <Chevron />
          </Link>
        </div>
      </MobileMenu>
    </ul>
  )
}

const HeaderComponent: FC<HeaderComponentProps> = ({
  className,
  darkMode,
  isBlog,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/Header\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            home
            goToTheHomePage
            collapseMenu
            expandMenu
            more
            login
            trialCta,
            trialDescriptionPoints
            tryItFree
            links
            getStarted
            try
            sales
            request
            dropdowns {
              name
              items {
                link
                name
                description
                target
              }
              leftLink
              request
            }
            bannerText
            pinnedBanner {
              button
              info
            }
            blog
          }
          relativePath
        }
      }
    }
  `)
  const texts = useComponentTexts(data)
  const scroll = useScrollPosition()
  const direction = useScrollDirection()

  if (darkMode) {
    return (
      <>
        <nav className={`${styles.nav} ${styles.darkMode}`}>
          <DesktopNav
            texts={texts}
            variant="scroll-up"
            scrollDirection={direction}
          />
          <MobileNav texts={texts} darkMode={darkMode} />
        </nav>
      </>
    )
  }

  if (isBlog) {
    return (
      <>
        <nav
          className={`${styles.nav} ${direction === "down" ? styles.blog : ""}`}
        >
          <TopBanner caption={texts.bannerText} />
          <DesktopNav
            texts={texts}
            isBlog={isBlog}
            variant="scroll-up"
            scrollDirection={direction}
          />
          <MobileNav texts={texts} />
        </nav>
        <PinnedBottomBanner
          className={styles.hideDesktop}
          scrollPosition={scroll}
          buttonText={texts.pinnedBanner.button}
          textInfo={texts.pinnedBanner.info}
        />
      </>
    )
  }

  return (
    <>
      <nav
        className={`${styles.nav} ${styles.hideMobile} ${
          className && scroll === 0 ? className : ""
        } ${direction === "down" ? styles.show : styles.hide} ${
          darkMode ? styles.darkMode : ""
        }`}
      >
        <DesktopNav
          texts={texts}
          variant="scroll-down"
          scrollDirection={direction}
        />
      </nav>

      <nav
        className={`${styles.nav} ${styles.hideMobile} ${
          className && scroll === 0 ? className : ""
        } ${direction === "up" ? styles.show : styles.hide} ${
          darkMode ? styles.darkMode : ""
        }`}
      >
        <TopBanner caption={texts.bannerText} />
        <DesktopNav
          texts={texts}
          variant="scroll-up"
          scrollDirection={direction}
        />
      </nav>

      <nav
        className={`${styles.nav} ${styles.hideDesktop} ${
          className && scroll === 0 ? className : ""
        } ${darkMode ? styles.darkMode : ""}`}
      >
        <MobileNav texts={texts} darkMode={darkMode} />
      </nav>
      <PinnedBottomBanner
        className={styles.hideDesktop}
        scrollPosition={scroll}
        buttonText={texts.pinnedBanner.button}
        textInfo={texts.pinnedBanner.info}
      />
    </>
  )
}

export default HeaderComponent
