import { useEffect, useState, useRef } from "react"

export function useScrollDirection() {
  const [direction, setDirection] = useState<"up" | "down">("up")

  const scrollRef = useRef<number>(0)

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    scrollRef.current = window.scrollY

    const listener = () => {
      let maxScrollY = document.body.scrollHeight - window.innerHeight
      let scrollY = window.scrollY

      if (scrollY < 0) {
        scrollY = 0
      }

      if (scrollY > maxScrollY) {
        scrollY = maxScrollY
      }

      const diff = scrollRef.current - scrollY
      scrollRef.current = scrollY

      switch (true) {
        case diff < 0:
          return setDirection("down")
        case diff > 0:
          return setDirection("up")
        default:
          return
      }
    }

    window.addEventListener("scroll", listener)

    return () => window.removeEventListener("scroll", listener)
  }, [])

  return direction
}
