import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    stroke="currentColor"
    viewBox="0 0 36 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="YouTube"
      fill="currentColor"
      d="M35.64,6.042A10.937,10.937,0,0,0,34,2,4.35,4.35,0,0,0,30.6.4C25.564,0,18.008,0,18.008,0H17.991S10.434,0,5.4.4A4.351,4.351,0,0,0,2,2,10.963,10.963,0,0,0,.36,6.042,51.18,51.18,0,0,0,0,12v4a50.814,50.814,0,0,0,.36,5.947A10.951,10.951,0,0,0,2,26a5.159,5.159,0,0,0,3.759,1.6C8.639,27.905,18,28,18,28s7.564-.014,12.6-0.415A4.338,4.338,0,0,0,34,26a10.967,10.967,0,0,0,1.64-4.051A50.847,50.847,0,0,0,36,16V12A51.113,51.113,0,0,0,35.64,6.042ZM14,20V8l10,6Z"
    />
  </svg>
))
