import { useLayoutEffect } from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

export const useLockBodyScroll = (
  ref: React.RefObject<HTMLElement>,
  active = true,
) => {
  useLayoutEffect(() => {
    if (ref.current) {
      if (active) {
        disableBodyScroll(ref.current)
      } else {
        enableBodyScroll(ref.current)
      }
    }

    return () => {
      if (ref.current) {
        enableBodyScroll(ref.current)
      }
    }
  }, [active])
}
