import React, { FC, Fragment } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import Link from "src/utils/Link"
import ExternalLink from "src/components/Icons/ExternalLink"

const MoreMenu: FC<Props> = ({
  links,
  rightOption,
  leftOption,
  lang,
  ...props
}) => {
  return (
    <div {...props} className={styles.dropdown}>
      <div className={styles.body}>
        <div className={styles.list} aria-haspopup="true">
          {links.map(({ id, url, name, description, target }) => (
            <Fragment key={id}>
              <Link
                className={styles.link}
                lang={lang}
                to={url}
                target={target}
              >
                <h4 className={styles.title}>
                  {name}
                  {target === "_blank" && (
                    <ExternalLink className={styles.externalLink} />
                  )}
                </h4>
                <p className={styles.description}>{description}</p>
              </Link>
            </Fragment>
          ))}
        </div>
        <div className={styles.actions}>
          <Link className={styles.actionLink} lang={lang} to={leftOption.url}>
            {leftOption.name}
          </Link>
          <Link className={styles.actionLink} lang={lang} to={rightOption.url}>
            {rightOption.name}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MoreMenu
