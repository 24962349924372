import React, { FC } from "react"
import SEO from "src/components/SEO"
import Header from "src/components/Header"
import Footer from "src/components/Footer"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import { useFlowCookies } from "src/hooks/useFlowCookies"

export const Layout: FC<Props> = ({
  children,
  className,
  headerClassname,
  hideDefaultJSONLD,
  darkMode,
  isBlog,
  ...props
}) => {
  useFlowCookies()

  return (
    <>
      <SEO {...props} hideDefaultJSONLD={hideDefaultJSONLD} />
      <Header className={headerClassname} darkMode={darkMode} isBlog={isBlog} />
      <main
        className={`${styles.main} ${className} ${
          darkMode ? styles.darkMode : ""
        } `}
      >
        {children}
      </main>
      <Footer darkMode={darkMode} />
    </>
  )
}
