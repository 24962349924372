import React, { FC } from "react"
import { Props } from "./types"
import Link from "src/utils/Link"
import * as styles from "./styles.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import { usePages } from "src/context/pages"

const SecondFooter: FC<Props> = ({ lang, darkMode }) => {
  const { getPage } = usePages()

  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/SecondFooter\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            columns {
              link,
              name,
              description
              target
            }
          }
          relativePath
        }
      }
    }
  `)

  const {
    columns,
  }: {
    columns: Array<{
      link: string
      name: string
      description: string
      target?: string
    }>
  } = useComponentTexts(data)

  return (
    <div
      className={`content-wrapper ${styles.grid} ${
        darkMode ? styles.darkMode : ""
      }`}
    >
      {columns.map(column => {
        const page = getPage(column.link)
        return (
          <div className={styles.column} key={column.name}>
            <Link
              key={`column-${page}-title`}
              lang={lang}
              className={styles.link}
              to={page.url}
              target={column.target}
            >
              <span className={styles.title}>{column.name}</span>
              <span className={styles.description}>{column.description}</span>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default SecondFooter
