import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 358.89 99.19"
    {...props}
  >
    {children}
    <path
      d="M359,496.28a5,5,0,0,1,5.28-5.28h25.59a5,5,0,0,1,5.27,5.28v86.79a5,5,0,0,1-5.27,5.27H364.28a5,5,0,0,1-5.28-5.27Zm44.19,17.22a4.36,4.36,0,0,0,4.61,4.62h38.78a4.36,4.36,0,0,0,4.61-4.62V495.61a4.36,4.36,0,0,0-4.61-4.61H407.8a4.36,4.36,0,0,0-4.61,4.61Zm0,38.83A4.36,4.36,0,0,0,407.8,557h32.53a4.36,4.36,0,0,0,4.61-4.62V533.27a4.35,4.35,0,0,0-4.61-4.62H407.8a4.35,4.35,0,0,0-4.61,4.62Zm58,8.31c0,21.9,13.85,28.5,27.57,28.5,5,0,10.68-.8,14.77-2.77,2.9-1.46,3-3.83.39-5.28-6.33-3.56-9.09-10.42-9.09-20.05l.26-64.76a5,5,0,0,0-5.27-5.28H466.5a5,5,0,0,0-5.28,5.28Zm92.85-49.72a36.92,36.92,0,0,0-16,3c-3.3,1.58-3.56,4.88-.13,5.94,11.07,3.69,15,12.92,15,22.55v14c0,12.8,8,18.86,16,18.86s16-6.06,16-17.67V543.76c0-23-14.91-32.71-30.87-32.84m-13.58,78.21a36.92,36.92,0,0,0,16-3c3.3-1.59,3.56-4.88.14-5.94-11.08-3.69-15.05-12.92-15.05-22.55v-14c0-12.79-7.91-18.86-15.95-18.86s-16,6.07-16,17.67v13.85c0,23,14.9,32.71,30.86,32.84M688.34,515.8a5,5,0,0,0-5.28,5.28v35.34c0,15,8.84,20.32,17.68,20.32,10.42,0,17.15-7.13,17.15-18.6V521.08a5,5,0,0,0-5.28-5.28Zm-84.54,0a5,5,0,0,0-5.28,5.28v38.11c0,23.61,15,31,29.94,31,3.56,0,9-.66,12.53-2.24,2.77-1.32,2.9-3.69.4-5.15-7.39-4.22-10.56-11.87-10.56-20.83V521.08a5,5,0,0,0-5.27-5.28Zm42.47,0a5,5,0,0,0-5.28,5.28v35.74c0,22.95,17.81,32.44,30.86,32.44,4.49,0,9.5-.79,13.19-2.9,2.38-1.45,2.77-3.82.27-5-7.39-3.56-12.27-11.47-12.27-22.82V521.08a5,5,0,0,0-5.28-5.28Z"
      transform="translate(-359 -491)"
    />
  </svg>
))
