import React, { FC, useContext } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import Link from "src/utils/Link"
import Check from "src/components/Icons/Check"
import Globe from "src/components/Icons/Globe"
import { useStaticQuery, graphql } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import "core-js/es/object/from-entries"
import { LangContext } from "src/context/lang"
import { usePages } from "src/context/pages"
import ArrowLink from "src/components/ArrowLink"

const LanguageSelector: FC<Props> = ({ lang, className, darkMode }) => {
  const { i18n } = useContext(LangContext)
  const { getPage } = usePages()

  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/LanguageSelector\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            moreLanguagesComing
            signUp
            comingDate
            languages
          }
          relativePath
        }
      }
    }
  `)

  const texts: {
    languages: [string, string][]
    moreLanguagesComing: string
    signUp: string
    comingDate: string
  } = useComponentTexts(data)

  const langObject = Object.fromEntries(texts.languages)
  const selectedLangName = langObject[lang]

  const availableLanguages = texts.languages.filter(([key]) =>
    i18n.locales.includes(key),
  )

  const disabledLanguages = texts.languages.filter(
    ([key]) => !i18n.locales.includes(key),
  )

  return (
    <div
      className={`${styles.selector}${className ? " " + className : ""} ${
        darkMode ? styles.darkMode : ""
      }`}
    >
      <div className={styles.selectorButton}>
        <Globe />
        {selectedLangName}
      </div>

      <div className={styles.menuContainer}>
        <div className={styles.menu}>
          <ul className={styles.available}>
            {availableLanguages.map(([key, name]) => (
              <li key={key}>
                <Link
                  className={lang === key ? styles.active : undefined}
                  lang={key}
                  to="."
                >
                  {name}

                  <span aria-hidden="true">
                    <Check />
                  </span>
                </Link>
              </li>
            ))}
          </ul>

          <div className={styles.coming}>
            <p>
              {texts.moreLanguagesComing}
              {"\n"}
              <ArrowLink text={texts.signUp} to={getPage("global").url} />
            </p>

            <span className={styles.sectionTitle}>{texts.comingDate}</span>

            <ul>
              {disabledLanguages.map(([key, name]) => (
                <li key={key} lang={key}>
                  {name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LanguageSelector
