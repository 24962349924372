import { useContext } from "react"
import { LangContext } from "src/context/lang"

// TODO
//   Ideally, we would call useStaticQuery within this hook, to abstract its
//   consumers one hook call. This is not possible until this issue is resolved:
//   https://github.com/gatsbyjs/gatsby/issues/13764
export const useComponentTexts = ({ allFile: { nodes } }: any) => {
  const { lang, i18n } = useContext(LangContext)

  function findByLang(lang: string) {
    const node = nodes.find(({ relativePath }: any) => {
      const matches = /components\/.+\/(.+)\.yml/.exec(relativePath)
      return matches && matches[1] === lang
    })

    return node ? node.childYaml : undefined
  }

  return findByLang(lang) || findByLang(i18n.defaultLocale)
}
