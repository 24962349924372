import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    stroke="currentColor"
    viewBox="0 0 36 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      fill="currentColor"
      d="M11.321,29.968c13.585,0,21.015-11.543,21.015-21.555,0-.328,0-0.654-0.022-0.979A15.261,15.261,0,0,0,36,3.513a14.451,14.451,0,0,1-4.242,1.192A7.574,7.574,0,0,0,35,.516a14.566,14.566,0,0,1-4.69,1.838,7.264,7.264,0,0,0-10.45-.329,7.709,7.709,0,0,0-2.137,7.239A20.8,20.8,0,0,1,2.506,1.348,7.7,7.7,0,0,0,4.792,11.461a7.19,7.19,0,0,1-3.352-.948v0.1a7.535,7.535,0,0,0,5.926,7.427,7.2,7.2,0,0,1-3.335.129,7.42,7.42,0,0,0,6.9,5.261,14.592,14.592,0,0,1-9.173,3.25A14.912,14.912,0,0,1,0,26.566a20.529,20.529,0,0,0,11.321,3.4"
    />
  </svg>
))
