import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"

export const PinnedBottomBanner: FC<Props> = ({
  className,
  scrollPosition = 0,
  buttonText,
  textInfo,
}) => {
  const visibilityClass = scrollPosition >= 100 ? styles.showIn : styles.showOut
  const { getPage } = usePages()
  return (
    <div className={`${styles.container} ${className} ${visibilityClass}`}>
      <Link
        className={`button button-second ${styles.button}`}
        to={getPage("signup").url}
      >
        {buttonText}
      </Link>
      <span className={styles.info}>{textInfo}</span>
    </div>
  )
}
