import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 40"
    fill="currentColor"
    {...props}
  >
    {children}
    <path
      id="Checkmark"
      className="cls-1"
      d="M43.821,1.554L19.089,38.665A3,3,0,0,1,16.6,40H15.42a3,3,0,0,1-2.494-1.335L0.192,19.556A1,1,0,0,1,1.024,18H4.763a3.006,3.006,0,0,1,2.5,1.336l8.745,13.121L36.748,1.336A3.006,3.006,0,0,1,39.248,0h3.739A1,1,0,0,1,43.821,1.554Z"
    />{" "}
  </svg>
))
