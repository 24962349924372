import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="External_Link"
      data-name="External Link"
      className="cls-1"
      d="M42,20.5V10.215L18.661,33.554a1.5,1.5,0,0,1-2.123,0l-2.123-2.123a1.5,1.5,0,0,1,0-2.123L37.722,6H27.5A1.5,1.5,0,0,1,26,4.5v-3A1.5,1.5,0,0,1,27.5,0H45a3,3,0,0,1,3,3V20.5A1.5,1.5,0,0,1,46.5,22h-3A1.5,1.5,0,0,1,42,20.5ZM8,42H32a2,2,0,0,0,2-2V29.5A1.5,1.5,0,0,1,35.5,28h3A1.5,1.5,0,0,1,40,29.5V40a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V16A8,8,0,0,1,8,8H18.5A1.5,1.5,0,0,1,20,9.5v3A1.5,1.5,0,0,1,18.5,14H8a2,2,0,0,0-2,2V40A2,2,0,0,0,8,42Z"
    />
  </svg>
))
