import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    stroke="currentColor"
    viewBox="0 0 15 7"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path fill="none" d="M1 0l6.5 6.563L14 0" />
  </svg>
))
