import { Page, PageObject } from "src/pages"

export interface Option {
  id: Page["id"]
  addSeparator?: boolean
  name: string
  description?: string
  url: string
  target?: string
}

const optionExtras: Record<Page["id"], Pick<Option, "addSeparator">> = {}

export function createOptionFromLinkEntry(
  page: [Page["id"], string, string?],
  pages: Record<Page["id"], Page>,
) {
  const [id, name] = page
  const option: Option = {
    id,
    name,
    target: page.length >= 3 ? page[2] : undefined,
    url: `/${id}`,
    ...pages[id],
    ...optionExtras[id],
  }

  return option
}

export function createOptionFromPageObject(
  { name, link, description, target }: PageObject,
  pages: Record<Page["id"], Page>,
) {
  const id = link
  const option: Option = {
    name,
    id,
    description,
    target,
    url: `/${id}`,
    ...pages[id],
    ...optionExtras[id],
  }

  return option
}
