import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import Link from "src/utils/Link"
import LinkArrow from "src/components/Icons/LinkArrow"
import LinkArrowReverse from "src/components/Icons/LinkArrowReverse"

const ArrowLink: FC<Props> = ({
  as: Component = Link,
  className = "",
  text,
  to,
  reverse,
  target,
  rel,
}) => (
  <Component
    to={to}
    className={`${styles.arrowLink} ${className}`}
    role="tooltip"
    target={target}
    rel={rel}
  >
    {reverse && <LinkArrowReverse className={styles.arrowLeft} />}
    <span>{text}</span>
    {!reverse && <LinkArrow className={styles.arrowRight} />}
  </Component>
)

export default ArrowLink
